import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { UserState } from 'const';
import { OPERATOR } from 'const/condition';
import { queryClient } from 'query-client';
import React from 'react';
import { useGetStaffQuery, useResetTurnCountMutation, useUpdateTurnCountMutation } from 'services/staff';
import { getFullName } from 'utils/naming';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.colors.ghostwhite,
        minHeight: '100vh',
        padding: theme.spacing(4)
    },
    app: {
        maxWidth: 600,
        background: 'white',
        borderRadius: 20,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(3)
    },
    staffList: {},
    staffItem: {
        minHeight: `40px`,
        width: '100%'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -40,
        marginLeft: -40
    },
    button: {
        padding: `4px !important`,
        minWidth: `40px`
    }
}));

function generateTurnCount(turnCount) {
    const count = [];

    let rest = turnCount;

    for (let i = 1; i < turnCount; i++) {
        rest = turnCount - i;
        count.push(1);
    }

    if (rest !== 0) {
        count.push(rest);
    }

    return count;
}

export default function TurnCount() {
    const classes = useStyles();
    const { mutate } = useUpdateTurnCountMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(['staff']);
        }
    });

    const { mutate: resetMutate } = useResetTurnCountMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(['staff']);
        }
    });

    const { data } = useGetStaffQuery({
        query: {
            staffState: {
                [OPERATOR.equal]: UserState.Active
            }
        }
    });

    const staff = data?.items || [];

    const handleCountChange = ({ staffId, count }) => {
        mutate({
            staffId,
            body: {
                amount: count,
                isOverride: false
            }
        });
    };

    const handleReset = () => {
        resetMutate();
    };

    return (
        <Grid className={classes.root} direction="column" container alignItems="center">
            <Grid direction="column" container className={classes.app}>
                <Grid item container direction="row" spacing={3} style={{ marginBottom: `12px` }}>
                    <Grid item>
                        <Typography variant="h4">Staff turn management</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleReset} variant="contained" color="primary">
                            Reset
                        </Button>
                    </Grid>
                </Grid>

                <Grid container item direction="column" spacing={3} className={classes.staffList}>
                    {staff?.map((stf) => {
                        const turnCount = stf?.turnCount || 0;
                        const counts = generateTurnCount(turnCount);

                        return (
                            <Grid item container key={stf?.id} direction="column">
                                <Grid item container direction="row" justifyContent="space-between">
                                    <Grid style={{ flex: 1 }} item>
                                        <Typography variant="body2">{getFullName(stf)}</Typography>
                                    </Grid>
                                    <Grid item spacing={2} direction="row">
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    onClick={() =>
                                                        handleCountChange({
                                                            staffId: stf?.id,
                                                            count: -0.5
                                                        })
                                                    }
                                                    variant="outlined"
                                                    className={classes.button}
                                                >
                                                    -
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() =>
                                                        handleCountChange({
                                                            staffId: stf?.id,
                                                            count: 0.5
                                                        })
                                                    }
                                                    color="primary"
                                                    variant="outlined"
                                                    className={classes.button}
                                                >
                                                    +
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="row" alignItems="center" spacing={1}>
                                    {counts.map((count, index) => {
                                        return (
                                            <Grid item key={index}>
                                                {count === 1 ? '🟩' : `📐`}
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}
